body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nunito sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

tspan {
  font-family: "Nunito sans";
}

a {
  text-decoration: unset;
}

* {
  box-sizing: border-box;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */
/* 
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cfd8dc !important;
}

.leaflet-popup-content p {
  margin: 0px !important;
}

.canvasjs-chart-credit {
  display: none;
}

.Infobox .infobox-body,
.infobox-info {
  max-height: 520px !important;
  max-width: 420px !important;
  min-width: 320px !important;
  color: black !important;
}
