.svgFill * {
  fill: currentColor;
  stroke: inherit;
}

.svgStroke * {
  fill: inherit;
  stroke: currentColor;
}

svg {
  display: block;
}
