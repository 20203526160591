.table-custom.table-custom-has-fix-left {
  .table-cell-fix-left-last {
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      width: 30px;
      transform: translateX(100%);
      transition: box-shadow 0.3s;
      content: '';
      pointer-events: none;
      box-shadow: inset 10px 0 8px -8px #00000026;
    }
  }
}
.table-custom.table-custom-has-fix-right {
  .table-cell-fix-right-last {
    &:after {
      position: absolute;
      top: 0;
      bottom: -1px;
      left: 0;
      width: 30px;
      transform: translateX(-100%);
      transition: box-shadow 0.3s;
      content: '';
      pointer-events: none;
      box-shadow: inset -10px 0 8px -8px #00000026;
    }
  }
}
